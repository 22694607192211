import React, { FC } from 'react';
import Layout from 'components/common/Layout/Layout';
import Header from 'components/common/Header/Header';
import styles from './Contact.module.scss';
import GoogleMapReact from 'google-map-react';
import { Row, Col } from 'react-flexbox-grid';
import { Icon } from 'semantic-ui-react';
import { mapStyles } from './mapStyle';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import { graphql, useStaticQuery } from 'gatsby';
import Text from 'components/common/Text/Text';
import Gap from 'components/common/Gap/Gap';

const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          contact {
            tel
            fax
            email
            fbLink
            twitterLink
            linkedInLink
            name1
            name2
            name3
            address
            nip
            regon
            krs
            bankAccount
            openingHours
          }
        }
      }
    }
  }
`;

const GoogleMarker: FC<{ lat: number; lng: number }> = () => (
  <Icon name="map marker alternate" className={styles.mapMarker} />
);

const Contact = () => {
  const { contact } = useStaticQuery(query).allDataJson.edges[0].node;

  return (
    <Layout>
      <Header withBackground block />
      <Gap top="md">
        <SectionHeading
          largeHeading="Kontakt"
          className={styles.heading}
          centered
        />
      </Gap>
      <main className={styles.main}>
        <Row between="xs">
          <Col xs={12} lg={6} className={styles.textCol}>
            <Text block fontSize="1.4rem" weight="bold" transform="uppercase">
              {contact.name2}
            </Text>
            <Text block fontSize="1.4rem" transform="uppercase">
              {contact.name3}
            </Text>
            <Gap top="xl">
              <Text block fontSize="1.2rem">
                NIP: {contact.nip}
              </Text>
              <Text block fontSize="1.2rem">
                REGON: {contact.regon}
              </Text>
              <Text block fontSize="1.2rem">
                KRS: {contact.krs}
              </Text>
            </Gap>
            <Gap top="xxl">
              <Text block fontSize="1.2rem" weight="bold" transform="uppercase">
                Adres
              </Text>
              <Text block fontSize="1.2rem">
                {contact.address}
              </Text>
            </Gap>
            <Gap top="lg">
              <Text block fontSize="1.2rem" weight="bold" transform="uppercase">
                Kontakt
              </Text>
              <Text block fontSize="1.2rem">
                Tel: {contact.tel}
              </Text>
              <Text block fontSize="1.2rem">
                Email: {contact.email}
              </Text>
            </Gap>
            <Gap top="lg">
              <Text block fontSize="1.2rem" weight="bold" transform="uppercase">
                Konto
              </Text>
              <Text block fontSize="1.2rem">
                {contact.bankAccount}
              </Text>
            </Gap>
            <Gap top="lg" bottom="lg">
              <Text block fontSize="1.2rem" weight="bold" transform="uppercase">
                Godziny otwarcia
              </Text>
              <Text block fontSize="1.2rem">
                {contact.openingHours}
              </Text>
            </Gap>
          </Col>
          <Col xs={12} lg={6}>
            <div className={styles.mapWrapper}>
              <GoogleMapReact
                defaultCenter={{ lat: 50.296426, lng: 18.658580 }}
                defaultZoom={20}
                bootstrapURLKeys={{
                  key: 'AIzaSyCa653JiDj4FKAWqgcyTThpPstST_BxXmY',
                }}
                options={{
                  styles: mapStyles,
                  gestureHandling: 'greedy',
                }}
              >
                <GoogleMarker lat={50.296426} lng={18.658580} />
              </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </main>
    </Layout>
  );
};

export default Contact;
